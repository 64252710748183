// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import company from './company'
import commons from './commons'
import settings from './settings'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  company,
  commons,
  settings
})

export default rootReducer
