// ** Initial State
const initialState = {
  currency: {
    name: "THB",
    symbol: "฿"
  }
}

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CURRENCY':
      return { ...state, ...action.data }
    default:
      return state
  }
}

export default settingsReducer
