// ** Initial State
const initialState = {
  companyId: '',
  currentStep: '',
  name: "",
  address: "",
  addressLineTwo: "",
  bluebookDocs: null,
  useVirtualOffice: false,
  type: {},
  shareTypes: [],
  shareholders: [],
  directors: []
}

const companyDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COMPANY_DETAILS':
      return { ...state, ...action.data }
    case 'SET_CURRENT_STEP':
      return { ...state, currentStep: action.data }
    case 'SET_COMPANY_ID':
      return { ...state, companyId: action.data }
    case 'SET_DEFAULT_COMPANY_DETAILS':
      return initialState
    default:
      return state
  }
}

export default companyDetailsReducer
